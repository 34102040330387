* {
  font-family: 'Nunito', sans-serif;
  /* font-family: "Poppins", sans-serif; */
  /* font-family: 'Roboto', sans-serif;
  font-family: 'Ubuntu', sans-serif; */
  line-height: 120%;
  letter-spacing: 0.4px;
}
body {
  background-color: FFFDFA;
  margin: 0 auto;
  padding: 0;
}
