.text-decoration {
  text-decoration: none;
  padding-left: 20px;
  font-size: 16px;
  color: black;
  font-family: "Ubuntu", sans-serif;
}

.aktif {
  color: blue;
}
